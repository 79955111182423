import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
//import Image from "../components/image"
import { Link, graphql } from "gatsby"

export default ({ data }) => (
  <Layout>
    <SEO title="404: Not found" />
    <h1 className="underline-title">Page introuvable</h1>
    <p>
      <br />
      Ouf, un faux pas?<br />
      Eh ben, tout le monde s'égare<br />
      À un moment<br />
      Comme cette antilope moderne<br />
      qui alla diner chez son chasseur<br />
      Je te laisse imaginer la suite<br />
    <p>
    </p>
      Tu vois,<br />
      On s'égare déjà...<br />
    </p>
    <p>
      Et si ton chemin était juste là?
    </p>
    {/*
    <Image />
    */}
    <h4 className='underline-title'>Publications</h4>
    <Link to={data.allBooksJson.nodes[0].link}>{data.allBooksJson.nodes[0].book}</Link> - 1977<br />
    <Link to={data.allBooksJson.nodes[1].link}>{data.allBooksJson.nodes[1].book}</Link> - 1978<br />
    <Link to={data.allBooksJson.nodes[2].link}>{data.allBooksJson.nodes[2].book}</Link> - 1988<br />
  </Layout>
)

//export default NotFoundPage
export const query = graphql`
  query {
    allBooksJson {
      nodes {
        book
        pages
        link
        pageInfo {
          node {
            link
            poemTitle
          }
        }
      }
    }
  }
`
